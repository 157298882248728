import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';
import { convertIdToText } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class SystemListViewModel {
  constructor() {
    this.boardData = {
      title:'시스템 로그',
      drawDataList: [
        {
          title:'id',
          width:'50px',
          value:'id',
          isAlignLeft: true,
        },
        {
          title:'requestUri',
          width:'',
          value:'requestUri',
          isAlignLeft: true,
          isEllip: true,
        },
        {
          title:'requestAt',
          width:'144px',
          value:'requestAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isAlignLeft: true,
        },
        {
          title:'responseStatus',
          width:'120px',
          value:'responseStatus',
        },
        {
          title:'responseAt',
          width:'156px',
          value:'responseAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isAlignLeft: true,
        },
      ],
      option:{
        isTotal: true,
        isSize: false
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isSystemDetailPopup = false;
    this.systemDetailData = {};
  }

  init(){
    this.getSystemLogList();
  }
  onClickRow(rowId,rowData){
    this.isSystemDetailPopup = true;
    this.systemDetailData = rowData;
  }
  onClickSystemDetailPopupClose(){
    this.isSystemDetailPopup = false;
    this.systemDetailData = {};
  }
  onSearch(){
    this.getSystemLogList();
  }
  getSystemLogList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.SYSTEM_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData;
      // this.paginationData.totalCount = resultData.total;
      // this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}